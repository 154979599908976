<template>
  <section class="create-quiz-wrapper">
    <div id="scene-container" ref="sceneContainer"></div>
    <div class="container-filter w-100 pt-5 pb-3 d-flex">
      <div class="child-nav d-flex mt-4 ml-auto justify-content-end mr-5">
        <input
          type="text"
          class="form-control morphism w-25 mr-3"
          :placeholder="$store.state.listTemplateQuestion.length != null ? $store.state.templateCurrentSoal + '/' + $store.state.listTemplateQuestion.length : '0/0'"
        />
        <select name="" id="poin" v-model="$store.state.templateData.point" class="form-control mr-3 w-75">
          <option :value="5">5 Points</option>
          <option :value="10">10 Points</option>
          <option :value="15">15 Points</option>
          <option :value="20">20 Points</option>
        </select>
        <select name="" id="time" v-model="$store.state.templateData.duration" class="form-control mr-3 w-75">
          <option :value="10">10 Seconds</option>
          <option :value="30">30 Seconds</option>
        </select>
        <select
          name=""
          id="time"
          v-model="$store.state.templateData.question_type"
          class="form-control w-100"
          @change="tipeQuestion"
        >
          <option value="Multiple Choice">Pilihan Ganda</option>
          <option value="True or False">Benar / Salah</option>
          <option value="Fill in the Blank">Short Answers</option>
          <!-- <option value="Match">Menjodohkan</option> -->
        </select>
      </div>
    </div>
    <div class="container-fluid">
      <!-- <div class="subNav">
            <div class="row">
                <div class="col-md-4">

                </div>
                <div class="col-md-7 d-flex justify-content-end subNav-text">
                    <input type="text" class="form-control morphism mr-3" placeholder="0/1">
                    <select name="" id="poin" class="form-control mr-3 w-custom">
                        <option value="">10 Points</option>
                    </select>
                    <select name="" id="time" class="form-control mr-3 w-custom">
                        <option value="">30 Seconds</option>
                    </select>
                    <select name="" id="time" v-model="tipeQuiz" class="form-control mr-3 w-custom">
                        <option value="pg">Pilihan Ganda</option>
                        <option value="trueFalse">Benar/Salah</option>
                    </select>
                    <button type="button" class="btn btn-primary text-center"><img src="@/assets/images/plus-white.svg" class="mr-2"/>Tambah Pertanyaan</button>
                </div>
            </div>
        </div> -->
      <div class="w-100">
        <div class="d-flex">
          <div class="container-question ml-auto">
            <div class="question w-100">
              <div class="question-text mb-2">
                <b-alert variant="danger" :show="$store.state.showAlertSoalTemp" fade>
                  {{ $store.state.responseSoal }}   
                </b-alert>
                <div class="d-flex align-items-center">
                  <div v-if="$store.state.templateData.question_type != 'Match'" class="mx-3">
                    <img v-if="$store.state.templateData.image_url == null || $store.state.templateData.image_url == ''" @click="$bvModal.show('modal-insert-img')" src="@/assets/images/icons/img-grup.svg" alt="img" type="button">
                  <!-- <button v-if="$store.state.templateData.image_url == null || $store.state.templateData.image_url == ''" type="button" class="btn btn-outline-light" @click="$bvModal.show('modal-insert-img')"><b-icon icon="images"></b-icon></button> -->
                  <div v-else>
                    <img :src="$store.state.templateData.image_url" :alt="$store.state.templateData.image_url" @click="$bvModal.show('modal-insert-img')" class="img-question">
                    <div class="d-flex justify-content-center mt-2">
                      <button type="button" class="btn btn-sm btn-outline-light mr-2" @click="deleteImage"><b-icon icon="trash"></b-icon></button>
                      <button type="button" class="btn btn-sm btn-outline-light" @click="$bvModal.show('modal-insert-img')"><b-icon icon="pencil-square"></b-icon></button>
                    </div>
                  </div>
                  </div>
                  <el-tiptap
                    v-if="$store.state.templateData.question_type != 'Fill in the Blank'"
                    :extensions="extensions"
                    lang="en"
                    placeholder="Type in your question here ..."
                    v-model="$store.state.templateData.question"
                    @onUpdate="cekQuestionLenght"
                    :charCounterCount = 'false'
                  />
                  <el-tiptap v-else
                    :extensions="extensions"
                    lang="en"
                    placeholder="Type in your question here ..."
                    v-model="question_isian_singkat"
                    @onUpdate="cekQuestionLenght"
                    :charCounterCount = 'false'
                  />
                </div>
                <p class="text-right mb-0 mr-2 text-white mt-2">Characters : {{ 120 - lengthQuestion($store.state.templateData.question) }}</p>
              </div>
              <div class="row choice-wrapper">
                <template v-if="$store.state.templateData.question_type == 'Multiple Choice'">
                  <div class="col-6 p-2">
                    <div class="p-2" :class="$store.state.opsi1Template == true || $store.state.opsi1Template == '1' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer1Temp" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi1Template"
                          vs-value="1"
                          :disabled="$store.state.opsi2Template != null || $store.state.opsi3Template != null || $store.state.opsi4Template != null"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.templateData.option_1" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer1Length" />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer1($store.state.templateData.option_1) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="p-2" :class="$store.state.opsi2Template == true || $store.state.opsi2Template == '2' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer2Temp" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi2Template"
                          vs-value="2"
                          :disabled="$store.state.opsi1Template != null || $store.state.opsi3Template != null || $store.state.opsi4Template != null"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.templateData.option_2" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer2Length" />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer2($store.state.templateData.option_2) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="p-2" :class="$store.state.opsi3Template == true || $store.state.opsi3Template == '3' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer3Temp" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi3Template"
                          :disabled="$store.state.opsi1Template != null || $store.state.opsi2Template != null || $store.state.opsi4Template != null"
                          vs-value="3"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.templateData.option_3" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer3Length" />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer3($store.state.templateData.option_3) }}</p>
                      </div>                    
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="p-2" :class="$store.state.opsi4Template == true || $store.state.opsi4Template == '4' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer4Temp" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi4Template"
                          :disabled="$store.state.opsi1Template != null || $store.state.opsi2Template != null || $store.state.opsi3Template != null"
                          vs-value="4"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.templateData.option_4" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer4Length" />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer4($store.state.templateData.option_4) }}</p>
                      </div>                    
                    </div>
                  </div>
                </template>

                <template v-else-if="$store.state.templateData.question_type == 'True or False'">
                  <div class="col-6 p-2">
                    <div class="p-2 position-relative" :class="$store.state.opsi1Template == true || $store.state.opsi1Template == '1' ? 'true-choice' : 'choice'">
                      <vs-checkbox
                        color="success"
                        v-model="$store.state.opsi1Template"
                        vs-value="1"
                        class="position-absolute checkbox-choice"
                        :disabled="$store.state.opsi2Template != null || $store.state.opsi3Template != null || $store.state.opsi4Template != null"
                      ></vs-checkbox>
                      <el-tiptap :readonly='true' v-model="$store.state.templateData.option_1" :extensions="extensions_choice_tf" lang="en" />
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="p-2 position-relative" :class="$store.state.opsi2Template == true || $store.state.opsi2Template == '2' ? 'true-choice' : 'choice'">
                      <vs-checkbox
                        color="success"
                        v-model="$store.state.opsi2Template"
                        vs-value="2"
                        class="position-absolute checkbox-choice"
                        :disabled="$store.state.opsi1Template != null || $store.state.opsi3Template != null || $store.state.opsi4Template != null"
                      ></vs-checkbox>
                      <el-tiptap :readonly='true' v-model="$store.state.templateData.option_2" :extensions="extensions_choice_tf" lang="en" />
                    </div>
                  </div>
                </template>

                <template v-else-if="$store.state.templateData.question_type == 'Match'">
                  <!-- QUESTION -->
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-blue">
                      <div class="p-3 h-100 mx-2">
                        <div class="text-left">
                          <img v-if="$store.state.templateData.option_1 == null || $store.state.templateData.option_1 == ''" src="@/assets/images/icons/img-grup.svg" alt="img" type="button" @click="inserImgMenjodohkan(1)">
                          <img v-else src="@/assets/images/icons/img-grup.svg" alt="img" type="button" style="cursor: not-allowed">
                        </div>
                        <img v-if="$store.state.templateData.img_option_1 != null && $store.state.templateData.img_option_1 != ''" :src="$store.state.templateData.img_option_1" alt="1" class="img-fluid mt-3" style="max-height:7.7rem; max-width:12rem;">
                        <div v-if="$store.state.templateData.img_option_1 == null || $store.state.templateData.img_option_1 == ''" class="mx-auto my-5">
                          <input type="text" autocomplete="off" v-model="$store.state.templateData.option_1" placeholder="teks 1" name="teks1q" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-green">
                      <div class="p-3 h-100 mx-2">
                        <div class="text-left">
                          <img v-if="$store.state.templateData.option_2 == null || $store.state.templateData.option_2 == ''" src="@/assets/images/icons/img-grup.svg" alt="img" type="button" @click="inserImgMenjodohkan(2)">
                          <img v-else src="@/assets/images/icons/img-grup.svg" alt="img" type="button" style="cursor: not-allowed">
                        </div>
                        <img v-if="$store.state.templateData.img_option_2 != null && $store.state.templateData.img_option_2 != ''" :src="$store.state.templateData.img_option_2" alt="2" class="img-fluid mt-3" style="max-height:7.7rem; max-width:12rem;">
                        <div v-if="$store.state.templateData.img_option_2 == null || $store.state.templateData.img_option_2 == ''" class="mx-auto my-5">
                          <input type="text" autocomplete="off" v-model="$store.state.templateData.option_2" placeholder="teks 2" name="teks2q" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-danger">
                      <div class="p-3 h-100 mx-2">
                        <div class="text-left">
                          <img v-if="$store.state.templateData.option_3 == null || $store.state.templateData.option_3 == ''" src="@/assets/images/icons/img-grup.svg" alt="img" type="button" @click="inserImgMenjodohkan(3)">
                          <img v-else src="@/assets/images/icons/img-grup.svg" alt="img" type="button" style="cursor: not-allowed">
                        </div>
                        <img v-if="$store.state.templateData.img_option_3 != null && $store.state.templateData.img_option_3 != ''" :src="$store.state.templateData.img_option_3" alt="3" class="img-fluid mt-3" style="max-height:7.7rem; max-width:12rem;">
                        <div v-if="$store.state.templateData.img_option_3 == null || $store.state.templateData.img_option_3 == ''" class="mx-auto my-5">
                          <input type="text" autocomplete="off" v-model="$store.state.templateData.option_3" placeholder="teks 3" name="teks3q" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-warning">
                      <div class="p-3 h-100 mx-2">
                        <div class="text-left">
                          <img v-if="$store.state.templateData.option_4 == null || $store.state.templateData.option_4 == ''" src="@/assets/images/icons/img-grup.svg" alt="img" type="button" @click="inserImgMenjodohkan(4)">
                          <img v-else src="@/assets/images/icons/img-grup.svg" alt="img" type="button" style="cursor: not-allowed">
                        </div>
                        <img v-if="$store.state.templateData.img_option_4 != null && $store.state.templateData.img_option_4 != ''" :src="$store.state.templateData.img_option_4" alt="4" class="img-fluid mt-3" style="max-height:7.7rem; max-width:12rem;">
                        <div v-if="$store.state.templateData.img_option_4 == null || $store.state.templateData.img_option_4 == ''" class="mx-auto my-5">
                          <input type="text" autocomplete="off" v-model="$store.state.templateData.option_4" placeholder="teks 4" name="teks4q" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- ANSWER -->
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-blue">
                      <div class="p-3 d-flex align-items-center justify-content-center h-100 ml-2">
                        <input type="text" autocomplete="off" v-model="$store.state.templateData.match_1" placeholder="teks 1" name="teks1a" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-green">
                      <div class="p-3 d-flex align-items-center justify-content-center h-100 ml-2">
                        <input type="text" autocomplete="off" v-model="$store.state.templateData.match_2" placeholder="teks 2" name="teks2a" />
                      </div>
                    </div>
                  </div>  
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-danger">
                      <div class="p-3 d-flex align-items-center justify-content-center h-100 ml-2">
                        <input type="text" autocomplete="off" v-model="$store.state.templateData.match_3" placeholder="teks 3" name="teks3a" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-warning">
                      <div class="p-3 d-flex align-items-center justify-content-center h-100 ml-2">
                        <input type="text" autocomplete="off" v-model="$store.state.templateData.match_4" placeholder="teks 4" name="teks4a" />
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="$store.state.templateData.question_type == 'Fill in the Blank'">
                  <div class="col-6 p-2">
                    <div class="p-2" :class="$store.state.opsi1Template == true || $store.state.opsi1Template == '1' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer1Temp" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi1Template"
                          vs-value="1"
                          :disabled="$store.state.opsi2Template != null || $store.state.opsi3Template != null || $store.state.opsi4Template != null"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.templateData.option_1" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer1Length" />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer1($store.state.templateData.option_1) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="p-2" :class="$store.state.opsi2Template == true || $store.state.opsi2Template == '2' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer2Temp" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi2Template"
                          vs-value="2"
                          :disabled="$store.state.opsi1Template != null || $store.state.opsi3Template != null || $store.state.opsi4Template != null"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.templateData.option_2" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer2Length" />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer2($store.state.templateData.option_2) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="p-2" :class="$store.state.opsi3Template == true || $store.state.opsi3Template == '3' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer3Temp" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi3Template"
                          :disabled="$store.state.opsi1Template != null || $store.state.opsi2Template != null || $store.state.opsi4Template != null"
                          vs-value="3"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.templateData.option_3" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer3Length" />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer3($store.state.templateData.option_3) }}</p>
                      </div>                    
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="p-2" :class="$store.state.opsi4Template == true || $store.state.opsi4Template == '4' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer4Temp" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi4Template"
                          :disabled="$store.state.opsi1Template != null || $store.state.opsi2Template != null || $store.state.opsi3Template != null"
                          vs-value="4"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.templateData.option_4" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer4Length" />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer4($store.state.templateData.option_4) }}</p>
                      </div>                    
                    </div>
                  </div>
                </template>
              </div>
              <div v-if="$store.state.showAlertSoalTemp == false && $store.state.showAlertAnswer1Temp == false && $store.state.showAlertAnswer2Temp == false && $store.state.showAlertAnswer3Temp == false && $store.state.showAlertAnswer4Temp == false" class="d-flex justify-content-end mb-5">
                  <button v-if="$store.state.templateCurrentSoal == 1 || $store.state.templateCurrentSoal == 0 " style="cursor : not-allowed" class="btn btn-light mr-2 disabled"><b-icon icon="chevron-left"></b-icon></button>
                  <button v-else class="btn btn-light mr-2" @click="renderSoal($store.state.templateCurrentSoal -1)"><b-icon icon="chevron-left"></b-icon></button>
                  <button v-if="$store.state.templateCurrentSoal == $store.state.listTemplateQuestion.length" class="btn btn-light disabled" style="cursor : not-allowed"><b-icon icon="chevron-right"></b-icon></button>
                  <button v-else class="btn btn-light" @click="renderSoal($store.state.templateCurrentSoal+1)"><b-icon icon="chevron-right"></b-icon></button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Upload image for question -->
    <b-modal
      id="modal-insert-img"
      ref="modal"
      centered 
      @ok="saveImage"
    >
      <form ref="form">
        <b-form-group
          label="Input image URL"
          label-for="image-input"
        >
          <b-form-input
            id="image-input"
            v-model="image_url"
            required
            type="url"
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

    <!-- Modal Upload image for option -->
    <b-modal
      id="modal-insert-menjodohkan"
      ref="modal"
      centered 
      @ok="saveImageMenjodohkan"
    >
      <form ref="form">
        <b-form-group
          label="Input image URL"
          label-for="image-input"
        >
          <b-form-input
            id="image-input"
            v-model="img_url_jodoh"
            required
            type="url"
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </section>
</template>
<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Stats from "stats.js";
import { ElementTiptap } from "element-tiptap";
// import { addQuestions } from '@/services/question/question.service'
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Image,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  Link,
} from "element-tiptap";
import "element-ui/lib/theme-chalk/index.css";
import "element-tiptap/lib/index.css";
import $ from "jquery";

export default {
  components: {
    "el-tiptap": ElementTiptap,
  },
  data() {
    return {
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        // new Image(),
        new Table({ resizable: true }),
        new TableHeader(),
        new TableRow(),
        new TableCell(),
        new Link(),
      ],
      extensions_choice_pg: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Image(),
      ],
      extensions_choice_tf: [new Doc(), new Text(), new Paragraph(), new Image()],
      container: null,
      scene: null,
      camera: null,
      controls: null,
      renderer: null,
      stats: null,
      mixers: [],
      clock: null,
      opsi1: null,
      opsi2: null,
      opsi3: null,
      opsi4: null,
      tipeQuiz: "pg",
      additional : false,
      image_url : "",
      listQuestion:[],
      formData: {
        quiz_id : '',
        question : '',
        option_1 : '',
        option_2 : '',
        option_3 : '',
        option_4 : '',
        correct_answer : '',
      },
      question_isian_singkat : null,
      jodohkanIndex : null,
      img_url_jodoh : '',
    };
  },

  watch : {
    question_isian_singkat(value){
      console.log(value)
      this.$store.state.templateData.option_1 = this.isianSingkatSplit(value)
      this.$store.state.templateData.question = value;
      if(this.$store.state.templateData.option_1 != null && this.$store.state.templateData.option_1 != "" && this.$store.state.templateData.question != "" && this.$store.state.templateData.question != null) {
        this.$store.state.opsi1Template = true
      } else {
        this.$store.state.opsi1Template = false
      }
    }
  },
  
  methods: {
    init() {
      // set container
      this.container = this.$refs.sceneContainer;

      // add stats
      this.stats = new Stats();
      this.clock = new THREE.Clock();
      this.container.appendChild(this.stats.dom);

      // add camera
      const fov = 5.5; // Field of view
      const aspect = this.container.clientWidth / this.container.clientHeight;
      const near = 0.1; // the near clipping plane
      const far = 1000; // the far clipping plane
      const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      camera.position.set(0, 2, 12);
      this.camera = camera;

      // create scene
      this.scene = new THREE.Scene();

      // add lights
      const ambientLight = new THREE.HemisphereLight(
        0xffffff, // bright sky color
        0x222222, // dim ground color
        1 // intensity
      );
      const mainLight = new THREE.DirectionalLight(0xffffff, 4.0);
      mainLight.position.set(10, 10, 10);
      this.scene.add(ambientLight, mainLight);

      // add controls
      this.controls = new OrbitControls(this.camera, this.container);
      // this.controls.enabled = false
      this.controls.minPolarAngle = this.controls.maxPolarAngle = Math.PI * 0.45;
      this.controls.enableZoom = false; //To enable zoom mode on 3d

      // create renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.gammaFactor = 2.2;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.physicallyCorrectLights = true;
      this.container.appendChild(this.renderer.domElement);

      // set aspect ratio to match the new browser window aspect ratio
      this.camera.aspect =
        this.container.clientWidth / this.container.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );

      //load model

      const loader = new GLTFLoader();

      const onLoad = (result, position) => {
        const model = result.scene.children[0];
        model.position.copy(position);
        model.scale.set(0.5, 0.5, 0.5);

        const mixer = new THREE.AnimationMixer(model);
        this.mixers.push(mixer);

        const animation = result.animations[0];
        const action = mixer.clipAction(animation);
        action.play();

        this.scene.add(model);
      };

      const parrotPosition = new THREE.Vector3(0, -0.55, 0);
      loader.load(
        "/three-assets/scene.glb",
        (gltf) => onLoad(gltf, parrotPosition),
        undefined,
        undefined
      );

      this.renderer.setAnimationLoop(() => {
        this.update();
        this.render();
      });
    },

    update() {
      const delta = this.clock.getDelta();
      this.mixers.forEach((mixer) => mixer.update(delta));
    },

    render() {
      this.renderer.render(this.scene, this.camera);
    },

    setSideBar() {
      this.$store.commit("IS_SIDEBAR_ACTIVE", false);
    },

    tambahPertanyaan(){
      if(this.opsi1 != null){
        this.formData.correct_answer = this.opsi1 
      } else if(this.opsi2 != null){
        this.formData.correct_answer = this.opsi2 
      } else if(this.opsi3 != null){
        this.formData.correct_answer = this.opsi3 
      } else if(this.opsi4 != null){
        this.formData.correct_answer = this.opsi4 
      } else {
        this.formData.correct_answer = null;
      }
      var id = this.$route.query.key
      this.listQuestion.push(this.formData)
      localStorage.setItem("listQuestion", JSON.stringify(this.listQuestion));
      this.$router.push({ name: 'Edit-Template', query: {key: id} })

      console.log(this.listQuestion)
    },

    addIndex(){
      if(this.$store.state.templateData.question_type == 'True or False'){
        this.$store.state.templateData.option_3 = null
        this.$store.state.templateData.option_4 = null
      }

      if(this.$store.state.templateData.question_type == 'Match'){
        this.$store.state.templateData.point_match_1 = '10'
        this.$store.state.templateData.point_match_2 = '10'
        this.$store.state.templateData.point_match_3 = '10'
        this.$store.state.templateData.point_match_4 = '10'
      }

      if(this.$store.state.opsi1Template != null){
        this.$store.state.templateData.correct_answer = "1" 
      } else if(this.$store.state.opsi2Template != null){
        this.$store.state.templateData.correct_answer = "2" 
      } else if(this.$store.state.opsi3Template != null){
        this.$store.state.templateData.correct_answer = "3" 
      } else if(this.$store.state.opsi4Template != null){
        this.$store.state.templateData.correct_answer = "4" 
      } else {
        this.$store.state.templateData.correct_answer = null;
      }

      const updateQ = this.$store.state.listTemplateQuestion.find(e => e._id === this.$store.state.templateData._id)
      localStorage.setItem("listQuestion", JSON.stringify(updateQ));

      const indexAlready = this.$store.state.listTemplateQuestion.find(element => element.index == this.$store.state.templateData.index) || null

      if(indexAlready != null){
        this.$store.state.templateCurrentSoal = this.$store.state.listTemplateQuestion.length
        this.$store.state.templateCurrentSoal += 1;
      } else {
        this.$store.state.templateCurrentSoal = this.$store.state.templateData.index + 1
      }
      this.$store.state.templateData = {
        quiz_id : '',
        question : '',
        option_1 : '',
        option_2 : '',
        option_3 : '',
        option_4 : '',
        correct_answer : '',
        question_type: 'Multiple Choice',
        index : this.$store.state.templateData.index+1
      }
    },

    renderSoal(index){
      let newForm = {
        quiz_id : '',
        question : '',
        option_1 : '',
        option_2 : '',
        option_3 : '',
        option_4 : '',
        correct_answer : '',
        index : this.$store.state.templateData.index+1
      }

      if(this.$store.state.templateData.question_type == 'True or False'){
        this.$store.state.templateData.option_3 = null
        this.$store.state.templateData.option_4 = null
      }

      if(this.$store.state.templateData.question_type == 'Match'){
        this.$store.state.templateData.point_match_1 = '10'
        this.$store.state.templateData.point_match_2 = '10'
        this.$store.state.templateData.point_match_3 = '10'
        this.$store.state.templateData.point_match_4 = '10'
      }

      if(this.$store.state.opsi1Template != null){
        this.$store.state.templateData.correct_answer = "1" 
      } else if(this.$store.state.opsi2Template != null){
        this.$store.state.templateData.correct_answer = "2" 
      } else if(this.$store.state.opsi3Template != null){
        this.$store.state.templateData.correct_answer = "3" 
      } else if(this.$store.state.opsi4Template != null){
        this.$store.state.templateData.correct_answer = "4" 
      } else {
        this.$store.state.templateData.correct_answer = null;
      }

      const updateQ = this.$store.state.listTemplateQuestion.find(e => e._id === this.$store.state.templateData._id)
      localStorage.setItem("listQuestion", JSON.stringify(updateQ));
      
      this.$store.state.templateCurrentSoal = index;
      console.log(this.$store.state.templateCurrentSoal)
      this.$store.state.templateData = this.$store.state.listTemplateQuestion.find(element => element.index == index) || newForm

      if(this.$store.state.templateData.question_type == 'Fill in the Blank'){
        this.question_isian_singkat = this.$store.state.templateData.question
      }

      if(this.$store.state.templateData.correct_answer == '1'){
        this.$store.state.opsi1Template = true
        this.$store.state.opsi2Template = null
        this.$store.state.opsi3Template = null
        this.$store.state.opsi4Template = null
      } else if(this.$store.state.templateData.correct_answer == '2'){
        this.$store.state.opsi1Template = null
        this.$store.state.opsi2Template = true
        this.$store.state.opsi3Template = null
        this.$store.state.opsi4Template = null
      } else if(this.$store.state.templateData.correct_answer == '3'){
        this.$store.state.opsi1Template = null
        this.$store.state.opsi2Template = null
        this.$store.state.opsi3Template = true
        this.$store.state.opsi4Template = null
      } else if(this.$store.state.templateData.correct_answer == '4'){
        this.$store.state.opsi1Template = null
        this.$store.state.opsi2Template = null
        this.$store.state.opsi3Template = null
        this.$store.state.opsi4Template = true
      } else {
        this.$store.state.opsi1Template = null
        this.$store.state.opsi2Template = null
        this.$store.state.opsi3Template = null
        this.$store.state.opsi4Template = null
      }
    },

    tipeQuestion(e){
      console.log(e.target.value)
      if(e.target.value == 'Multiple Choice'){
        this.$store.state.templateData.option_1 = ''
        this.$store.state.templateData.option_2 = ''
        this.$store.state.templateData.option_3 = ''
        this.$store.state.templateData.option_4 = ''
      } else if(e.target.value == 'True or False') {
        this.$store.state.templateData.option_1 = 'BENAR'
        this.$store.state.templateData.option_2 = 'SALAH'
        this.$store.state.opsi1Template = null
        this.$store.state.opsi2Template = null
        this.$store.state.opsi3Template = null
        this.$store.state.opsi4Template = null
      } else if(e.target.value == 'Fill in the Blank') {
        this.question_isian_singkat = this.$store.state.templateData.question
        this.$store.state.opsi1Template = null
        this.$store.state.opsi2Template = null
        this.$store.state.opsi3Template = null
        this.$store.state.opsi4Template = null
        this.$store.state.templateData.option_1 = ''
        this.$store.state.templateData.option_2 = ''
        this.$store.state.templateData.option_3 = ''
        this.$store.state.templateData.option_4 = ''
      } else if(e.target.value == 'Match'){
        this.$store.state.templateData.option_1 = ''
        this.$store.state.templateData.option_2 = ''
        this.$store.state.templateData.option_3 = ''
        this.$store.state.templateData.option_4 = ''
      }
    },

    saveImage(){
      this.$store.state.templateData.image_url = this.image_url
      this.image_url = ''
    },

    deleteImage(){
      this.$store.state.templateData.image_url = ''
    },

    cekQuestionLenght(e){
      const value = e
      const regex = value.replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 120) {
        // this.$store.state.templateData.question = e
        this.$store.state.showAlertSoalTemp = false
      } else {
        this.$store.state.showAlertSoalTemp = true
      }
    },
    
    lengthQuestion(e){
      if(e != undefined){
        const value = e
        // const prev = this.$store.state.templateData.question
        const regex = value.replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },
    
    cekAnswer1Length(e){
      const value = e
      const regex = value.replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        // this.$store.state.option_1.question = e
        this.$store.state.showAlertAnswer1Temp = false
      } else {
        this.$store.state.showAlertAnswer1Temp = true
      }
    },
    
    lengthAnswer1(e){
      if(e != undefined){
        const value = e
        // const prev = this.$store.state.templateData.question
        const regex = value.replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },
    
    cekAnswer2Length(e){
      const value = e
      const regex = value.replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        // this.$store.state.option_2.question = e
        this.$store.state.showAlertAnswer2Temp = false
      } else {
        this.$store.state.showAlertAnswer2Temp = true
      }
    },
    
    lengthAnswer2(e){
      if(e != undefined){
        const value = e
        // const prev = this.$store.state.templateData.question
        const regex = value.replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },
    
    cekAnswer3Length(e){
      const value = e
      const regex = value.replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        // this.$store.state.option_3.question = e
        this.$store.state.showAlertAnswer3Temp = false
      } else {
        this.$store.state.showAlertAnswer3Temp = true
      }
    },
    
    lengthAnswer3(e){
      if(e != undefined){
        const value = e
        // const prev = this.$store.state.templateData.question
        const regex = value.replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },
    
    cekAnswer4Length(e){
      const value = e
      const regex = value.replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        // this.$store.state.option_4.question = e
        this.$store.state.showAlertAnswer4Temp = false
      } else {
        this.$store.state.showAlertAnswer4Temp = true
      }
    },

    lengthAnswer4(e){
      if(e != undefined){
        const value = e
        // const prev = this.$store.state.templateData.question
        const regex = value.replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },

    isianSingkatSplit(e){
      let value = e
      let replaceValue = value.replace(/[{}]/g, '_')
      let splitedValue = replaceValue.split("_")
      let finalValue = null

       if(splitedValue[3] != undefined){
        finalValue = splitedValue[1] + ", " + splitedValue[3]

        return finalValue
      } else if(splitedValue[1] != undefined){
        finalValue = splitedValue[1]

        return finalValue
      }
    },
    
    updatePertanyaan(e){
        this.$store.state.templateData.question = e.slice(0,10)
        console.log("PERTANYAAN " + this.$store.state.templateData.question)
    },

    inserImgMenjodohkan(index){
      this.jodohkanIndex = index;
      this.$bvModal.show('modal-insert-menjodohkan')
    },

    saveImageMenjodohkan(){
        if(this.jodohkanIndex == 1){
          this.$store.state.templateData.img_option_1 = this.img_url_jodoh
          this.img_url_jodoh = ''
          this.jodohkanIndex = null
        } else if(this.jodohkanIndex == 2){
          this.$store.state.templateData.img_option_2 = this.img_url_jodoh
          this.img_url_jodoh = ''
          this.jodohkanIndex = null
        } else if(this.jodohkanIndex == 3){
          this.$store.state.templateData.img_option_3 = this.img_url_jodoh
          this.img_url_jodoh = ''
          this.jodohkanIndex = null
        } else if(this.jodohkanIndex == 4){
          this.$store.state.templateData.img_option_4 = this.img_url_jodoh
          this.img_url_jodoh = ''
          this.jodohkanIndex = null
        }
    },
  },
  computed: {
    hasAdditional(){
      console.log(this.opsi1)
        return this.opsi1.length >0;
    }
  },
  mounted() {
    $("section.create-quiz-wrapper").parent().addClass("m-0");

    this.setSideBar();
    this.init();
    let data = localStorage.getItem("listQuestion");
    this.$store.state.listTemplateQuestion = JSON.parse(data);

    let index = 1;
    this.$store.state.listTemplateQuestion.forEach((element) => {
        element.index = index
        index++;
    })

    localStorage.setItem("listQuestion", JSON.stringify(this.$store.state.listTemplateQuestion))
    this.$store.state.templateData.index = index;

    var index_id = this.$route.query.index
    if(index_id != undefined){
      this.$store.state.templateCurrentSoal = index_id
    } else {
      this.$store.state.templateCurrentSoal = index-1;
    }
    this.renderSoal(this.$store.state.templateCurrentSoal)

    if(this.$store.state.templateData.correct_answer == '1'){
        this.$store.state.opsi1Template = true
        this.$store.state.opsi2Template = null
        this.$store.state.opsi3Template = null
        this.$store.state.opsi4Template = null
      } else if(this.$store.state.templateData.correct_answer == '2'){
        this.$store.state.opsi1Template = null
        this.$store.state.opsi2Template = true
        this.$store.state.opsi3Template = null
        this.$store.state.opsi4Template = null
      } else if(this.$store.state.templateData.correct_answer == '3'){
        this.$store.state.opsi1Template = null
        this.$store.state.opsi2Template = null
        this.$store.state.opsi3Template = true
        this.$store.state.opsi4Template = null
      } else if(this.$store.state.templateData.correct_answer == '4'){
        this.$store.state.opsi1Template = null
        this.$store.state.opsi2Template = null
        this.$store.state.opsi3Template = null
        this.$store.state.opsi4Template = true
      } else {
        this.$store.state.opsi1Template = null
        this.$store.state.opsi2Template = null
        this.$store.state.opsi3Template = null
        this.$store.state.opsi4Template = null
      }
  },
};
</script>

<style scoped>
.child-nav input::-webkit-input-placeholder {
  text-align: center;
}
.img-question{
  width: 7rem;
  height: 7rem;
}
</style>

<style>
html {
  height: 100% !important;
}
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
.main-wrapper {
  height: 100% !important;
}
.main-container-fluid {
  /* height: 100% !important;
  margin-left: 0px !important;
  padding: 0 !important; */
}
.create-quiz-wrapper {
  background-image: url("../../../assets/images/background/bg-space-web.png");
  min-height: 120%;
  width: 100%;
  background-size: cover;
}
#scene-container {
  width: 120%;
  height: 114.8%;
  position: absolute;
  left: -40vw;
  top: 5vh;
}
#scene-container div {
  display: none !important;
}
.container-filter {
  background: rgba(12, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 1px solid rgba(12, 0, 0, 0.3);
  position: relative;
}
.container-question {
  width: 67.5%;
}
.question {
  top: 0%;
  left: 30%;
  width: 70%;
  height: 100%;
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
}
.question-text {
  position: relative;
  padding: 15px;
  background: rgba(12, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 0px solid rgba(12, 0, 0, 0.3);
  border-radius: 20px;
}
.question-text:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -42px;
  top: 17px;
  /* border: 10px solid transparent;
    border-right: 32px solid rgb(193,193,193); /* IE8 Fallback */
  /* border-right: 32px solid rgba(193,193,193,0.5);  */
  z-index: 2;
}
.question-text:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -45px;
  top: 50px;
  border: 15px solid transparent;
  border-right: 30px solid rgba(12, 0, 0, 0.6);
  z-index: 3;
}

.choice {
  background: rgba(12, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 0px solid rgba(12, 0, 0, 0.3);
  border-radius: 20px;
}

.checkbox-choice {
  right: 5px;
  top: 18px;
}

.subNav {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 15%;
  top: 0%;
  z-index: 2;
}

.subNav-text {
  position: relative;
  padding: 65px;
  margin-bottom: 10px;
}

.subNav-text input::-webkit-input-placeholder {
  text-align: center;
}

.morphism {
  background: rgba(255, 255, 255, 0.03);
  width: 7%;
}

.w-custom {
  width: 15% !important;
}
.el-tiptap-editor__menu-bar {
  background: none !important;
  border: none !important;
}
.el-tiptap-editor__content {
  background: none !important;
  color: white !important;
}
.el-tiptap-editor__footer {
  background: none !important ;
  border: none !important;
}
.el-tiptap-editor__content {
  border: 1px solid #fff !important;
  border-radius: 5px;
}
.el-tiptap-editor__menu-bar:before {
  background: none !important;
}
.el-tiptap-editor__command-button {
  color: #fff !important;
}

.true-choice {
  background: rgba(0, 220, 25, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 0px solid rgba(12, 0, 0, 0.3);
  border-radius: 20px;
}

.choice-menjodohkan{
  height: 14rem;
  width : 15rem;
  border-radius: 30px;
}

.choice-menjodohkan input{
  background: transparent;
  border: 0px;
  color: white;
  text-align: center;
}

.container-question ::placeholder {
   text-align: center;
   color: white; 
}

/* or, for legacy browsers */

.container-question ::-webkit-input-placeholder {
   text-align: center;
   color: white;
}

.container-question :-moz-placeholder { /* Firefox 18- */
   text-align: center;
   color: white;  
}

.container-question ::-moz-placeholder {  /* Firefox 19+ */
   text-align: center; 
   color: white; 
}

.container-question:-ms-input-placeholder {  
   text-align: center; 
   color: white;
}

.bg-green{
  background-color:#369D06 !important
}

.bg-blue{
  background-color:#2B87E3 !important
}

</style>
